<!--
 * @Author: your name
 * @Date: 2021-03-03 14:19:53
 * @LastEditTime: 2021-04-27 15:55:35
 * @LastEditors: Please set LastEditors
 * @Description: 仓库周报
 * @FilePath: \sd-vue-admin\src\views\project\report\manage\dayList\components\WarehouseDaily.vue
-->
<template>
  <div>
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            type="week"
            placeholder="选择日期"
            format="yyyy 第 WW 周"
            :picker-options="{
              firstDayOfWeek: 1,
            }"
            value-format="yyyy-MM-dd"
            style="width: 150px"
            @change="handletshijian"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword">
          <goodslist
            :f-key="form.keyword"
            @select-goods-all="selectGoods($event)"
            @choose-goods-keyword="keyname"
          ></goodslist>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <slot
            name="exportBtn"
            :start_date="form.start_date"
            :goods_id="form.goods_id"
            :brand_id="form.brand_id"
            :depot_id="form.depot_id"
          ></slot>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <h3>合计：</h3>
      <el-table stripe :data="tableData.total" style="width: 50%">
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <h3>库存变化明细：</h3>
      <el-table
        stripe
        :data="tableData.detailed"
        :span-method="arraySpanMethod"
      >
        <el-table-column
          v-for="(list, index) in colemd1"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import goodslist from '@/components/goodslist/index.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      goodslist,
    },
    data() {
      return {
        form: {
          start_date: '',
          depot_id: '', //供应商ID
          brand_id: '', //品牌
          keyword: '', //商品关键词
          goods_id: '', //商品ID
        },
        tableData: {},
        userdata: [],
        department: [],
        supplier: [],
        Brand: [],
        warehouse: [],
        url: {
          yuangong: '/baseAdmin/user/index',
          department: '/baseAdmin/depart/index',
          userlist: '/baseAdmin/common/user-list',
          supplier: '/purchaseAdmin/base/supp-list',
          Brand: '/baseAdmin/common/brand-list',
          warehouse: '/baseAdmin/common/depot-list',
          list: '/reportAdmin/operate/depot-week',
        },
        colemd: [
          {
            order: 1,
            label: '仓库',
            align: 'center',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 2,
            label: '库存参考成本',
            align: 'center',
            prop: 'const_amount',
            width: '',
          },
          {
            order: 3,
            label: '库存参考货值',
            align: 'center',
            prop: 'sell_amount',
            width: '',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '仓库',
            align: 'center',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 2,
            label: '品牌',
            align: 'center',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 3,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 4,
            label: '规格',
            align: 'center',
            prop: 'specs',
            width: '',
          },
          {
            order: 5,
            label: '单位换算',
            align: 'center',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 6,
            label: '期初库存',
            align: 'center',
            prop: 'number_s',
            width: '',
          },
          {
            order: 7,
            label: '期末库存',
            align: 'center',
            prop: 'number_e',
            width: '',
          },
          {
            order: 8,
            label: '变动',
            align: 'center',
            prop: 'number_c',
            width: '',
          },
          {
            order: 9,
            label: '采购',
            align: 'center',
            prop: 'number_21',
            width: '',
          },
          {
            order: 10,
            label: '采退',
            align: 'center',
            prop: 'number_15',
            width: '',
          },
          {
            order: 11,
            label: '调入',
            align: 'center',
            prop: 'number_22',
            width: '',
          },
          {
            order: 12,
            label: '调出',
            align: 'center',
            prop: 'number_13',
            width: '',
          },
          {
            order: 13,
            label: '销售',
            align: 'center',
            prop: 'number_112',
            width: '',
          },
          {
            order: 14,
            label: '退货',
            align: 'center',
            prop: 'number_23',
            width: '',
          },
          {
            order: 15,
            label: '盘盈',
            align: 'center',
            prop: 'number_24',
            width: '',
          },
          {
            order: 16,
            label: '盘亏',
            align: 'center',
            prop: 'number_14',
            width: '',
          },
          {
            order: 17,
            label: '报损',
            align: 'center',
            prop: 'number_16',
            width: '',
          },
          {
            order: 18,
            label: '组装',
            align: 'center',
            prop: 'number_25',
            width: '',
          },
          {
            order: 19,
            label: '拆出',
            align: 'center',
            prop: 'number_17',
            width: '',
          },
          {
            order: 20,
            label: '入库',
            align: 'center',
            prop: 'number_26',
            width: '',
          },
          {
            order: 21,
            label: '出库',
            align: 'center',
            prop: 'number_18',
            width: '',
          },
        ],
        spanArr: [],
      }
    },

    computed: {},
    watch: {
      'form.keyword'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    created() {},
    mounted() {
      this.qianyitian()
      this.handlerdepartment()
      this.hanlderuserlist()
      this.handlerlist()
      this.handlersupplier()
      this.handlerBrand()
      this.handleryuangong()
      this.handlerwarehouse()
    },
    methods: {
      getSpanArr(data) {
        let Arr = new Array()
        let pos = 0
        console.log(data, '执行了吗')
        try {
          for (var i = 0; i < data.length; i++) {
            if (i == 0) {
              Arr.push(1)
              pos = 0
            } else {
              // 判断当前元素与上一个元素是否相同
              console.log(data[i].type, '元素')
              if (data[i].depot_name === data[i - 1].depot_name) {
                Arr[pos] += 1
                Arr.push(0)
              } else {
                Arr.push(1)
                pos = i
              }
            }
            console.log(Arr)
          }
          this.spanArr = Arr
        } catch (error) {}
      },

      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        // console.log({ row, column, rowIndex, columnIndex })
        if (columnIndex === 0) {
          let _row = this.spanArr[rowIndex]
          let _col = _row > 0 ? 1 : 0
          console.log(`rowspan:${_row} colspan:${_col}`)
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      keyname(val) {
        console.log(val, '商品名称')
        this.form.keyword = val
      },
      selectGoods(row) {
        console.log('商品', row)
        if (row.goods_id == undefined) {
          this.form.goods_id = -1
        } else {
          this.form.goods_id = row.goods_id
        }
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerInquire() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.getSpanArr(this.tableData.detailed)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 员工列表
      handleryuangong() {
        postAction(this.url.yuangong, { status: 0, pageSize: -1 })
          .then((res) => {
            console.log(res, '员工列表')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handletshijian(e) {
        console.log(e, '')
        var dang = new Date(e).getTime()
        var hou = dang - 24 * 3600 * 1000
        var yesterday = new Date(hou)
        console.log('========', yesterday)
        let c =
          yesterday.getFullYear() +
          '-' +
          (yesterday.getMonth() > 9
            ? yesterday.getMonth() + 1
            : '0' + (yesterday.getMonth() + 1)) +
          '-' +
          (yesterday.getDate() > 9
            ? yesterday.getDate()
            : '0' + yesterday.getDate())
        let a = c.split('-')
        if (a.length > 0 && a[1].length == 3) {
          a[1] = a[1].substr(1)
          let c = a.join('-')
          this.form.start_date = c
          return c
        } else {
          this.form.start_date = c
          return c
        }
      },
      qianyitian() {
        console.log('qianyitian')
        var time = new Date().getTime()

        //  - 24 * 60 * 60 * 1000 * 7
        var time1 = new Date().getDay()

        var chuo = 0

        if (time1 == 0) {
          chuo = (7 - 1) * 24 * 60 * 60 * 1000
        } else {
          chuo = (time1 - 1) * 24 * 60 * 60 * 1000
        }

        var zhou = time - chuo
        var yesterday = new Date(zhou)
        var month = yesterday.getMonth()
        var day = yesterday.getDate()

        yesterday =
          yesterday.getFullYear() +
          '-' +
          (yesterday.getMonth() > 9
            ? yesterday.getMonth() + 1
            : '0' + (yesterday.getMonth() + 1)) +
          '-' +
          (yesterday.getDate() > 9
            ? yesterday.getDate()
            : '0' + yesterday.getDate())

        let a = yesterday.split('-')
        if (a.length > 0 && a[1].length == 3) {
          a[1] = a[1].substr(1)
          let c = a.join('-')
          this.form.start_date = c
          return c
        } else {
          this.form.start_date = yesterday
          return yesterday
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
