import { render, staticRenderFns } from "./PurchasingDaily.vue?vue&type=template&id=35207490&scoped=true&"
import script from "./PurchasingDaily.vue?vue&type=script&lang=js&"
export * from "./PurchasingDaily.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35207490",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/sd4-test-admin/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35207490')) {
      api.createRecord('35207490', component.options)
    } else {
      api.reload('35207490', component.options)
    }
    module.hot.accept("./PurchasingDaily.vue?vue&type=template&id=35207490&scoped=true&", function () {
      api.rerender('35207490', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/report/manage/weekList/components/PurchasingDaily.vue"
export default component.exports